//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    text: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    link: {
      type: String,
      default: null
    },
    section: {
      type: String,
      default: null
    },
    textColor: {
      type: String,
      default: null
    },
    hasArrow: {
      type: Boolean,
      default: true
    },
    customArrow: {
      type: String,
      default: null
    },
    hoverTextColor: {
      type: String,
      default: '#36ae37'
    }
  },
  data () {
    return {
      isHover: false
    }
  },
  computed: {
    getTextColor () {
      const { isHover, textColor, hoverTextColor } = this || {}
      if (isHover) {
        if (hoverTextColor && typeof hoverTextColor === 'string' && hoverTextColor !== '') {
          return hoverTextColor
        } else if (textColor && typeof textColor === 'string' && textColor !== '') {
          return textColor
        }
        return false
      } else if (textColor && typeof textColor === 'string' && textColor !== '') {
        return textColor
      }
      return false
    }
  },
  methods: {
    visitSection (e, elementId) {
      if (document.getElementById(elementId)) {
        e.preventDefault()
        setTimeout(() => {
          return window.scrollTo({
            top: document.querySelector('#'.concat(elementId)).offsetTop,
            behavior: 'smooth'
          })
        }, 250)
      }
    }
  }
}
