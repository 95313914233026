//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Item from './PartnersItem.vue'

export default {
  components: {
    Item
  },
  data () {
    return {
      windowWidth: typeof window !== 'undefined' ? window.innerWidth : null,
      isLargeScreen: typeof window !== 'undefined' ? window.matchMedia('(min-width: 1024px)').matches : false,
      col1: [
        require('@/assets/img/partnerSlider/ban.svg'),
        require('@/assets/img/partnerSlider/bil.svg'),
        require('@/assets/img/partnerSlider/br.svg'),
        require('@/assets/img/partnerSlider/dt.svg')
      ],
      col2: [
        require('@/assets/img/partnerSlider/dx.svg'),
        require('@/assets/img/partnerSlider/gar.svg'),
        require('@/assets/img/partnerSlider/kwai.svg'),
        require('@/assets/img/partnerSlider/mer.svg')
      ],
      col3: [
        require('@/assets/img/partnerSlider/mobile.svg'),
        require('@/assets/img/partnerSlider/net.svg'),
        require('@/assets/img/partnerSlider/open.svg'),
        require('@/assets/img/partnerSlider/paypal.svg')
      ],
      col4: [
        require('@/assets/img/partnerSlider/suby.svg'),
        require('@/assets/img/partnerSlider/ten.svg'),
        require('@/assets/img/partnerSlider/tik.svg'),
        require('@/assets/img/partnerSlider/tinder.svg')
      ],
      col5: [
        require('@/assets/img/partnerSlider/shoplaza.png'),
        require('@/assets/img/partnerSlider/tom.svg'),
        require('@/assets/img/partnerSlider/vpg.svg'),
        require('@/assets/img/partnerSlider/vtex.svg'),
        require('@/assets/img/partnerSlider/webeye.png')
      ],
      mobile: []
    }
  },
  mounted () {
    this.mobile = [
      this.col4[2], // tiktok
      this.col4[1], // tencent
      this.col4[3], // tinder
      this.col2[2], // kwai
      this.col3[1], // netease
      this.col2[1], // garena
      this.col3[0], // mobilelegends
      this.col3[2], // opencart
      this.col3[3], // paypal
      this.col5[2] // vtex
    ]
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  methods: {
    onResize () {
      clearTimeout(this.interval)
      this.interval = setTimeout(() => {
        this.windowWidth = window.innerWidth
        this.isLargeScreen = window.matchMedia('(min-width: 1024px)').matches
      }, 500)
    }
  }
}
