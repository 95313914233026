//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    imgs: {
      type: Array,
      default: null
    },
    time: {
      type: Number,
      default: 4000
    }
  },
  data () {
    return {
      hideLogoUp: true,
      sliding: false,
      slidingEnd: false,
      interval: null,
      currentImg: 0,
      nextImg: 1
    }
  },
  mounted () {
    if (this.interval) {
      clearInterval(this.interval)
    }
    this.setSlide(this.time)
  },
  methods: {
    setSlide (time) {
      this.interval = setInterval(this.slide, time)
    },
    slide () {
      this.hideLogoUp = false
      setTimeout(() => {
        this.sliding = true
        this.slide1()
      }, 100)
    },
    slide1 () {
      setTimeout(() => {
        this.slidingEnd = true
        if (this.currentImg < (this.imgs.length - 1)) {
          this.currentImg = this.currentImg + 1
        } else {
          this.currentImg = 0
        }
        if (this.nextImg < (this.imgs.length - 1)) {
          this.nextImg = this.nextImg + 1
        } else {
          this.nextImg = 0
        }
        this.sliding = false
        this.slide2()
      }, 600)
    },
    slide2 () {
      setTimeout(() => {
        this.slidingEnd = false
        this.hideLogoUp = true
      }, 600)
    }
  }
}
