var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.type)?_c('span',{staticClass:"custom-link arrow-down",style:({
    'color': _vm.getTextColor
  }),attrs:{"to":""},on:{"mouseover":function($event){_vm.isHover = true},"mouseleave":function($event){_vm.isHover = false},"click":function($event){return _vm.visitSection($event, _vm.section)}}},[_vm._v("\n  "+_vm._s(_vm.$t(_vm.text))+"\n  "),(_vm.hasArrow)?_c('div',{staticClass:"img-container"},[_c('b-img-lazy',{style:({
        'filter': _vm.textColor ? 'brightness('.concat( _vm.textColor === 'white' ? '100' : '0' ,')') : false
      }),attrs:{"src":require("@/assets/img/common/arrow-down.png"),"alt":"arrow down icon"}})],1):(_vm.customArrow)?_c('div',{staticClass:"img-container"},[_c('b-img-lazy',{staticClass:"custom-img",attrs:{"src":_vm.customArrow,"alt":"arrow icon"}})],1):_vm._e()]):(_vm.type === 'internal-link')?_c('nuxt-link',{staticClass:"custom-link in-link",style:({
    'color': _vm.getTextColor
  }),attrs:{"to":_vm.link ? _vm.localePath(_vm.link) : ''},on:{"mouseover":function($event){_vm.isHover = true},"mouseleave":function($event){_vm.isHover = false}}},[_vm._v("\n  "+_vm._s(_vm.$t(_vm.text))+"\n  "),(_vm.hasArrow)?_c('div',{staticClass:"img-container"},[_c('b-img-lazy',{style:({
        'filter': _vm.textColor ? 'brightness('.concat( _vm.textColor === 'white' ? '100' : '0' ,')') : false
      }),attrs:{"src":require("@/assets/img/common/arrow-down.png"),"alt":"arrow right icon"}})],1):(_vm.customArrow)?_c('div',{staticClass:"img-container"},[_c('b-img-lazy',{staticClass:"custom-img",attrs:{"src":_vm.customArrow,"alt":"arrow icon"}})],1):_vm._e()]):(_vm.type === 'external-link')?_c('a',{staticClass:"custom-link in-link",style:({
    'color': _vm.getTextColor
  }),attrs:{"href":_vm.link ? _vm.link : '',"rel":"nofollow","target":"_blank"},on:{"mouseover":function($event){_vm.isHover = true},"mouseleave":function($event){_vm.isHover = false}}},[_vm._v("\n  "+_vm._s(_vm.$t(_vm.text))+"\n  "),(_vm.hasArrow)?_c('div',{staticClass:"img-container"},[_c('b-img-lazy',{style:({
        'filter': _vm.textColor ? 'brightness('.concat( _vm.textColor === 'white' ? '100' : '0' ,')') : false
      }),attrs:{"src":require("@/assets/img/common/arrow-down.png"),"alt":"arrow right icon"}})],1):(_vm.customArrow)?_c('div',{staticClass:"img-container"},[_c('b-img-lazy',{staticClass:"custom-img",attrs:{"src":_vm.customArrow,"alt":"arrow icon"}})],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }