//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import StepsCarousel from '@/components/v3-components/StepsCarousel.vue'
// import Newsletter from '@/components/v3-components/newsletter.vue'
import PreFooterArea from '@/components/v3-components/pre-footer.vue'
export default {
  components: {
    StepsCarousel,
    // Newsletter,
    PreFooterArea
  },
  props: {
    text: {
      type: String,
      default: 'homepage-topfooter-title'
    },
    btnText: {
      type: String,
      default: 'homepage-topfooter-btn'
    }
  }
}
