var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'hero-section-container': true,
    'overflow': _vm.overflowImg
  }},[(_vm.paymentFlag)?_c('b-img-lazy',{staticClass:"payment-flag",attrs:{"src":_vm.paymentFlag,"alt":"flag"}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"top-screen"},[_c('div',{staticClass:"top-screen-content"},[_c('div',{class:{ 'text-area': true, 'long': _vm.imgPath || _vm.noImage ? false : true }},[_c('h1',{class:{
            'top-title': true,
            'medium': _vm.titleSize ? _vm.titleSize === 'M' : false,
            'small': _vm.titleSize ? _vm.titleSize === 'S' : false,
            'zhver': _vm.$i18n.locale === 'zh'
          },style:({
            'max-width': _vm.titleW ? _vm.titleW : false
          })},[_vm._v("\n          "+_vm._s(_vm.$t(_vm.title))+"\n        ")]),_vm._v(" "),(_vm.subtitle)?_c('p',{staticClass:"top-paragraph top-subtitle"},[_vm._v("\n          "+_vm._s(_vm.$t(_vm.subtitle))+"\n        ")]):_vm._e(),_vm._v(" "),(_vm.paragraph)?_c('p',{staticClass:"top-paragraph"},[_vm._v("\n          "+_vm._s(_vm.$t(_vm.paragraph))+"\n        ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"action-area"},[(_vm.mainBtn)?_c('span',{directives:[{name:"b-modal",rawName:"v-b-modal.contact-us-modal",modifiers:{"contact-us-modal":true}}],staticClass:"common-btn start-btn",attrs:{"data-agl-cvt":"1"}},[_vm._v("\n            "+_vm._s(_vm.$t(_vm.buttonText))+"\n          ")]):_vm._e(),_vm._v(" "),(_vm.btnSide)?_c('AnimatedLink',{staticClass:"side-text",attrs:{"text":_vm.sideText,"section":_vm.section,"text-color":"white","hover-text-color":"#5fbe99","has-arrow":false}}):_vm._e()],1)]),_vm._v(" "),(_vm.imgPath)?_c('div',{class:{
          'image-area': true,
          'overflow-center': _vm.overflowCenter
        }},[(_vm.imgPath)?_c('b-img-lazy',{class:{
            'top-img': true,
            'overflow': _vm.overflowImg,
            'filter': _vm.imgFilter,
            'overflow-center': _vm.overflowCenter
          },attrs:{"src":_vm.imgPath,"alt":_vm.imgAlt && _vm.imgAlt !== '' ? _vm.$t(_vm.imgAlt) : ''}}):_vm._e()],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"hero-bottom"},[_c('div',[_c('b-img-lazy',{attrs:{"src":require("@/assets/img/home/v3-home/left-arrow.svg"),"alt":""}})],1),_vm._v(" "),_c('div',[(!_vm.imgPath)?_c('b-img-lazy',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hiddenRightArrow),expression:"!hiddenRightArrow"}],attrs:{"src":require("@/assets/img/home/v3-home/right-arrow.svg"),"alt":""}}):_vm._e()],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }