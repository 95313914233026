//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AnimatedLink from '@/components/common/AnimatedLink.vue'
export default {
  components: { AnimatedLink },
  props: {
    title: {
      type: String,
      default: null
    },
    titleW: {
      type: String,
      default: null
    },
    titleSize: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    paragraph: {
      type: String,
      default: null
    },
    buttonText: {
      type: String,
      default: null
    },
    buttonLink: {
      type: String,
      default: null
    },
    imgPath: {
      type: String,
      default: null
    },
    noImage: {
      type: Boolean,
      default: false
    },
    mainBtn: {
      type: Boolean,
      default: true
    },
    btnSide: {
      type: Boolean,
      default: false
    },
    sideText: {
      type: String,
      default: 'home.know-more'
    },
    imgFilter: {
      type: Boolean,
      default: false
    },
    section: {
      type: String,
      default: 'block1'
    },
    overflowImg: {
      type: Boolean,
      default: false
    },
    overflowCenter: {
      type: Boolean,
      default: false
    },
    paymentFlag: {
      type: String,
      default: null
    },
    imgAlt: {
      type: String,
      default: null
    },
    hiddenRightArrow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  }
}
